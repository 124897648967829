<template>
  <div class="mainform">
      <!-- 创建网上发票 -->
   <div class="mainHeader">创建网上发票 <span @click="back" class="back">返回></span></div>
    <div class="form">
      <div class="header">网上发票信息 </div>
      <el-form  class="content bascform" ref="form" :model="form" :rules="rules" label-width="120px">
        <div class="col col4">
        <el-form-item label="公司名称" prop="corporateName">
          <el-select size="small" filterable remote
            :remote-method="requestCompanyOption"
            :loading="companyLoading"
            v-model="form.corporateName" placeholder="编码/名称">
            <el-option v-for="(item) in companyOptions"
            :key="item.value"
            :label="item.value"
            :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
        <div class="col col4">
          <el-form-item label="对账单类型" prop="statementType">
            <el-select v-model="form.statementType">
              <el-option :key="index" v-for="(itme,index) in statementType" :label="itme.label" :value="itme.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="开票方" prop="billingParty">
            <span class="onlyText">{{form.billingParty}}</span>
            <!-- <el-select size="small" filterable remote
              :remote-method="requestDrawerOption"
              :loading="drawerLoading"
              v-model="form.billingParty" placeholder="编码/名称">
              <el-option v-for="(item) in drawerOptions"
              :key="item.value"
              :label="item.value"
              :value="item.value">
              </el-option>
            </el-select> -->
          </el-form-item>
        </div>
         <div class="col col4">
          <el-form-item label="币种" prop="currency">
            <el-select v-model="form.currency">
              <el-option :key="index" v-for="(itme,index) in currencyOption" :label="itme.label" :value="itme.label">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
         <div class="col col4">
          <el-form-item label="供应商" prop="supplier">
            <el-select size="small" filterable remote
              :remote-method="requestSupplierOption"
              :loading="supplierLoading"
              v-model="form.supplier" placeholder="编码/名称">
              <el-option v-for="(item) in supplierOptions"
              :key="item.value"
              :label="item.value"
              :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
      </div>
       <div class="col col4">
            <el-form-item label="是否寄售" prop="consignmentOrNot">
                <el-radio-group v-model="form.consignmentOrNot">
                  <el-radio label="0">是</el-radio>
                  <el-radio label="1">否</el-radio>
                </el-radio-group>
            </el-form-item>
        </div>
        <div  class="col col4" style="vertical-align: top;">
          <el-form-item label="含税开票总额" prop="totalIncludingTax" >
            <span class="onlyText">
              {{form.totalIncludingTax}}
            </span>
            <!-- <el-input v-model="form.totalIncludingTax"></el-input> -->
          </el-form-item>
        </div>
      <div  class="col col4" style="vertical-align: top;">
          <el-form-item label="未税开票总额" prop="totalNotTaxed" >
            <span class="onlyText">
              {{form.totalNotTaxed}}
            </span>
            <!-- <el-input v-model="form.totalNotTaxed"></el-input> -->
          </el-form-item>
        </div>
        <div  class="col col4" style="vertical-align: top;">
          <el-form-item label="开票税额" prop="taxAmount">
            <span class="onlyText">
              {{form.taxAmount}}
            </span>
              <!-- <el-input v-model="form.taxAmount"></el-input> -->
          </el-form-item>
        </div>
      </el-form>
      <div class="header">网上发票明细</div>
      <div class="content bascform">
          <el-button size="small" type="primary" plain @click="addDetail">+添加明细</el-button>
          <!-- @tableAction="tableAction" -->
          <global-table @selection-change="setArpNum" max-height="350" ref="addTable" @select="rowSelect" :tableField="tableField" @diashow="diaShow" :tableData="tableData" ></global-table>
      </div>
        <dialog-box  ref="addDialog" dialogWidth="50%" :addData="form" :dialogShow="dialogShow" @handleClose='handleClose' @confirmAdd= "confirmAdd" title="添加明细"
        componentName="CreateonlineInvoicesdialog"
            :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'confirmAdd', type:'primary', size:'mini'}]">
        </dialog-box>
        <dialog-box  ref="dialogbox" dialogWidth="750px" :showData="showData" :dialogShow="mvNOdialogShow" @handleClose='handleClose'  title="物料凭证" componentName="materialVoucherNodialog"
         :buttonData="[{ label: '关闭', action: 'handleClose', size: 'mini' }]">
        </dialog-box>
      <div class="action" >
        <el-button class="submit" type="primary" size="medium" @click="submit">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>

import Component from '@/assets/js/components.js'
import { tableField, tableData, currencyOption, statementType, rules } from './js/createonlineInvoices'
import { requestForm, baseURL } from '@/assets/js/http.js'
import axios from 'axios'
export default {
  components: Component.components,
  name: 'CreateonlineInvoices',
  data () {
    return {
      tableField: tableField,
      tableData: tableData,
      dialogShow: false,
      currencyOption: currencyOption,
      statementType: statementType,
      supplierOptions: [],
      supplierLoading: false,
      showData: {},
      olddata: {},
      mvNOdialogShow: false,
      companyOptions: [],
      companyLoading: false,
      drawerOptions: [],
      drawerLoading: false,
      rules: rules,
      form: {},
      list: [],
      id: ''
    }
  },
  created () {
    this.form.currency = 'RMB'
    if (this.$store.state.loginInfo.supplierJson) {
      const supplierInfo = JSON.parse(this.$store.state.loginInfo.supplierJson)
      this.form.billingParty = supplierInfo.erpCode + '-' + supplierInfo.name
    }
  },
  watch: {
    '$store.state.loginInfo': function () {
      if (this.$store.state.loginInfo.supplierJson) {
        const supplierInfo = JSON.parse(this.$store.state.loginInfo.supplierJson)
        this.form.billingParty = supplierInfo.erpCode + '-' + supplierInfo.name
      }
    },
    form: {
      handler: function (val) {
        if (this.tableData) {
          if (this.tableData.length > 0) {
            for (const item in val) {
              if (item !== 'totalIncludingTax' && item !== 'totalNotTaxed' && item !== 'taxAmount') {
                if (val[item] !== this.olddata[item]) {
                  this.tableData = []
                  this.form.totalIncludingTax = 0.00
                  this.form.totalNotTaxed = 0.00
                  this.form.taxAmount = 0.00
                  break
                }
              }
            }
          }
        }
        this.olddata = JSON.parse(JSON.stringify(val))
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    setArpNum (selectData) {
      this.form.totalIncludingTax = 0
      this.form.totalNotTaxed = 0
      this.form.taxAmount = 0
      selectData.forEach(item => {
        this.form.totalIncludingTax = (this.form.totalIncludingTax + (parseFloat(item.amountIncludingTax) || 0))// .toFixed(2) // 含税
        this.form.totalNotTaxed = (this.form.totalNotTaxed + (parseFloat(item.amountNotTaxedThisTime) || 0))// .toFixed(2) // 未税
        this.form.taxAmount = (this.form.taxAmount + (parseFloat(item.currentTaxAmount) || 0))// .toFixed(2) // 税额
      })
      this.form.totalIncludingTax = this.form.totalIncludingTax.toFixed(2)
      this.form.totalNotTaxed = this.form.totalNotTaxed.toFixed(2)
      this.form.taxAmount = this.form.taxAmount.toFixed(2)
    },
    rowSelect (selectData, data) {
      // eslint-disable-next-line eqeqeq
      if (this.form.consignmentOrNot == 0) {
        var table = this.$refs.addTable.$refs.table
        const metCode = data.itemNumber
        for (const item of this.tableData) {
          if (item.itemNumber === metCode && data !== item) {
            this.$nextTick(() => {
              table.toggleRowSelection(item)
            })
          }
        }
      }
    },
    confirmAdd () { // 添加明细弹框的确认按钮
      // var num = this.$refs.addTable.selectData
      this.tableData = []
      var newListData = this.$refs.addDialog.$refs.content.$refs.addDetailtable.selectData
      if (newListData.length > 0) {
        newListData.forEach(item => {
          this.tableData.push(...item.list)
        })
        this.dialogShow = false
      } else {
        this.$message({
          message: '请选择要添加的明细',
          type: 'warning'
        })
      }
    },
    // tableAction: function (index, data, action) {
    //   if (action.id === '1') {
    //     this.tableData.splice(index, 1) // id为1移除一行
    //   }
    // },
    submit () {
      var isPass = true
      this.$refs.form.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      if (!isPass) {
        this.$message({
          message: '请将信息填写完整',
          type: 'error'
        })
        return false
      }
      this.id = ''
      var num = this.$refs.addTable.selectData
      num.forEach(item => {
        this.id = this.id + item.id + ','
      })
      const obj = {
        corporateName: this.form.corporateName,
        statementType: this.form.statementType,
        issuedBy: this.form.billingParty,
        currency: this.form.currency,
        consignmentOrNot: this.form.consignmentOrNot,
        materialInformationIds: this.id,
        isForce: 1 // 强制创建红字发票为0  非强制 1
      }
      obj.materialInformationIds = obj.materialInformationIds.substring(0, obj.materialInformationIds.length - 1)
      requestForm('/api/reconciliation/invoiceTotal/createOrder', 'post', obj).then((Response) => {
        if (Response.code === '200') {
          if (Response.data === '340') {
            this.$confirm(`${Response.msg}。是否继续？`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              obj.isForce = 0
              requestForm('/api/reconciliation/invoiceTotal/createOrder', 'post', obj).then((Response) => {
                if (Response.code === '200') {
                  this.$message({
                    message: '创建成功！',
                    type: 'success'
                  })
                  this.$router.push({
                    path: '/FinancialManagement/Supplierinvoice'
                  })
                }
              })
            }).catch(() => {
              this.$message({
                message: '已取消',
                type: 'info'
              })
            })
          } else {
            this.$message({
              message: '创建成功！',
              type: 'success'
            })
            this.$router.push({
              path: '/FinancialManagement/Supplierinvoice'
            })
          }
        }
      })
    },
    requestCompanyOption (val) { // 公司
      this.companyLoading = true
      this.companyOptions = []
      axios.post(`${baseURL}/api/sap/getCompanyCodeInfo`, val, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          token: localStorage.getItem('token')
        }
      }).then((Response) => {
        if (Response.data.code === 200) {
          for (const item of Response.data.data) {
            this.companyOptions.push({ key: `${item.companyCode}-${item.companyName}`, value: `${item.companyCode}-${item.companyName}` })
            this.companyCode = item.companyCode
          }
        } else {
          this.$message({
            message: Response.data.msg,
            type: 'error'
          })
        }
        this.companyLoading = false
      })
    },
    requestDrawerOption (val) { // 开票方
      this.drawerLoading = true
      this.drawerOptions = []
      requestForm('/api/reconciliation/reconciliation/queryBillingParty', 'post', { name: val }).then((Response) => {
        if (Response.code === '200') {
          for (const item of Response.data) {
            this.drawerOptions.push({ key: `${item.billingPartyCode}-${item.billingParty}`, value: `${item.billingPartyCode}-${item.billingParty}` })
          }
        }
        this.drawerLoading = false
      })
    },
    requestSupplierOption (val) { // 供应商
      this.supplierLoading = true
      this.supplierOptions = []
      requestForm('/api/reconciliation/reconciliation/querySupplierWhereBillingParty', 'post', { billingPartyCode: this.$store.state.loginInfo.erpCode, supplier: val }).then((Response) => {
        if (Response.code === '200') {
          for (const item of Response.data) {
            this.supplierOptions.push({ key: `${item.supplierCode}-${item.supplier}`, value: `${item.supplierCode}-${item.supplier}` })
          }
        }
        this.supplierLoading = false
      })
    },
    handleClose () {
      this.dialogShow = false
      this.mvNOdialogShow = false
    },
    diaShow (scope) { // 物料凭证号的弹窗
      var number = scope.row.materialVoucherNo
      requestForm('/api/reconciliation/materialDocument/queryVoucherNo?number=' + number, 'post', '').then((res) => {
        if (res.code === '200') {
          this.showData = res.data
          this.mvNOdialogShow = true
        }
      })
    },
    addDetail () {
      var isPass = true
      this.$refs.form.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      if (!isPass) {
        this.$message({
          message: '请将信息填写完整',
          type: 'error'
        })
        return false
      }
      this.dialogShow = true
    },
    back: () => {
      window.history.back()
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@/assets/css/form.scss';
  @import '@/assets/css/elForm.scss';
.mainform{
    :deep(.tableList){
        padding: 20px 0px;
    }
 .form{
     .content{
  max-width: 100%;
  margin: 0 auto;
 }

 .el-form .col{
   margin: 7px 0px;
 }
 }

}
</style>
