
export var tableField = [
  { selectType: 'selection', width: '', type: 'input' },
  { label: '对账单编号', code: 'number', type: 'input', width: '130' },
  { label: '对账单行号', code: 'lineNumber', type: 'input', width: '100' },
  {
    label: '物料凭证号',
    code: 'materialVoucherNo',
    type: 'vnode',
    width: '100',
    handle: function (scope) {
      if (scope) {
        return {
          methods: {
            clk: () => {
              this.$emit('diashow', scope)
            }
          },
          template: '<span><el-button size="mini" type="text" @click="clk">' + scope.row.materialVoucherNo + '</el-button></span>'
        }
      }
    }
  },
  { label: '物料凭证行号', code: 'materialVoucherLineNumber', type: 'input', width: '110' },
  { label: '采购单编号', code: 'purchaseOrderNumber', type: 'input', width: '100' },
  { label: '采购单行号', code: 'purchaseLineNumber', type: 'input', width: '100' },
  { label: '物料编号', code: 'itemNumber', type: 'input', width: '' },
  { label: '物料描述', code: 'materialDescription', type: 'input', width: '140' },
  { label: '对账单数量', code: 'reconciledQuantity', type: 'input', width: '110' },
  /* { label: '开票数量', code: 'numberOfOnlineInvoices', type: 'input', width: '110' }, */
  { label: '单价', code: 'unitPrice', type: 'amount', align: 'right', width: '' },
  { label: '含税金额', code: 'amountIncludingTax', type: 'amount', align: 'right', width: '' },
  { label: '未税金额', code: 'amountNotTaxedThisTime', type: 'amount', align: 'right', width: '' },
  { label: '税率', code: 'taxRate', type: 'input', width: '' },
  { label: '税额', code: 'currentTaxAmount', type: 'amount', align: 'right', width: '' },
  { label: '收货基地', code: 'base', type: 'input', width: '' },
  { label: '内部项目号', code: 'internalProjectNumber', type: 'input', width: '100' },
  { label: '内部项目标识', code: 'internalProjectIdentification', type: 'input', width: '110' },
  { label: '我的物料编号', code: 'myItemNumber', type: 'input', width: '110' },
  { label: '我的物料描述', code: 'myMaterialDescription', type: 'input', width: '110' }
  // {
  //   label: '操作',
  //   code: 'act',
  //   width: '',
  //   type: 'action',
  //   actionData: [
  //     {
  //       actionLabel: '移除',
  //       id: '1',
  //       operationButton: 'del'
  //     }
  //   ]
  // }

]

export var tableData = []
export var formData = {
  accountsPayableDetailList: [
  ],
  bankAccount: '',
  billMoney: 0,
  supplier: '', // 供应商
  bz: '',
  company: '',
  createDate: '',
  file: [],
  createUserId: '',
  createUserName: '',
  currency: '',
  prepayDetailList: [
  ]
}
export var currencyOption = [
  { label: 'RMB', value: '1' }
  // { label: 'USD', value: '2' }
]
export var statementType = [
  { label: '正常采购', value: '0' },
  { label: '分期付款', value: '1' }
]
export var rules = {
  corporateName: [
    { required: true, message: '请输入公司编码或名称', trigger: 'change' }
  ],
  // supplier: [
  //   { required: true, message: '请输入供应商', trigger: 'blur' }
  // ],
  statementType: [
    { required: true, message: '请选择对账单类型', trigger: 'change' }
  ],
  billingParty: [
    { required: true, message: '请选择开票方', trigger: 'change' }
  ],
  currency: [
    { required: true, message: '请选择币种', trigger: 'change' }
  ],
  consignmentOrNot: [
    { required: true, message: '请选择是否寄售', trigger: 'change' }
  ]
}
